<template>
  <div class="content_body inventoryCheck">
    <div class="nav_header">
      <el-row>
        <el-col :span="22">
          <el-form :inline="true" size="small" :model="searchForm" @keyup.enter.native="searchDataList">
            <el-form-item v-if="EntityList.length>1" label="仓库/门店">
              <el-select v-model="searchForm.EntityID" clearable filterable placeholder="请选择仓库/门店" :default-first-option="true" @change="searchDataList">
                <el-option v-for="item in EntityList" :key="item.ID" :label="item.EntityName" :value="item.ID">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="产品">
              <el-input v-model="searchForm.ProductName" placeholder="请输入产品名称、别名" clearable @keyup.enter.native="searchDataList" @clear="searchDataList"></el-input>
            </el-form-item>
            <el-form-item label="盘点状态">
              <el-select v-model="searchForm.BillStatus" clearable filterable placeholder="请选择单据状态" :default-first-option="true" @change="searchDataList" @clear="searchDataList">
                <el-option v-for="item in BillStatus" :key="item.ID" :label="item.Name" :value="item.ID">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="制单日期">
              <el-date-picker v-model="searchForm.DateTime" unlink-panels type="daterange" range-separator="至" value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions" clearable @change="searchDataList"></el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="searchDataList" v-prevent-click>搜索</el-button>
            </el-form-item>
          </el-form>
        </el-col>

        <el-col :span="2" class="text_right">
          <el-button type="primary" @click="addInventoryCheck" size="small" v-prevent-click>新建盘点</el-button>
        </el-col>
      </el-row>
    </div>

    <el-table class="martp_10" size="small" :data="data_list" tooltip-effect="light">
      <el-table-column prop="ID" label="单据号"></el-table-column>
      <el-table-column prop="EntityName" label="仓库/门店"></el-table-column>
      <el-table-column prop="BillStatus" label="盘点状态" :formatter="BillStatusFormatter"></el-table-column>
      <el-table-column prop="CreatedOn" label="制单时间"></el-table-column>
      <el-table-column prop="CreatedByName" label="操作人"></el-table-column>
      <el-table-column prop="Remark" label="备注" show-overflow-tooltip></el-table-column>
      <el-table-column label="操作" width="160px">
        <template slot-scope="scope">
          <el-button v-if="scope.row.BillStatus =='10'" type="danger" size="small" @click="cancellation(scope.row)" v-prevent-click>作废</el-button>
          <el-button v-if="scope.row.BillStatus =='10'" type="primary" size="small" @click="editInventoryCheck(scope.row)" v-prevent-click>继续</el-button>
          <el-button v-if="scope.row.BillStatus =='20'" type="primary" size="small" @click="showOutboundInfo(scope.row)" v-prevent-click>详情</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="pad_15 text_right">
      <el-pagination background v-if="paginations.total > 0" @current-change="inventoryCheckListHandleCurrentChange" :current-page.sync="paginations.page" :page-size="paginations.page_size" :layout="paginations.layout" :total="paginations.total"></el-pagination>
    </div>

    <!-- 新建库存盘点 编辑 -->
    <el-dialog custom-class="inventoryCheckDialogClass" :visible.sync="inventoryCheckDialogVisible" width="1100px" :close-on-click-modal="false" :close-on-press-escape="false" @close="closeAddInventoryCheck">
      <span slot="title" v-if="inventoryCheckFrom.isUpdate">继续盘点</span>
      <span slot="title" v-else>新建盘点</span>
      <div class="tip">基本信息</div>
      <el-form class="inventoryCheckDialogClassInfoFrom" :inline="true" :inline-message="true" label-width="100px" size="small" :model="inventoryCheckFrom" :rules="inventoryCheckFromRules" ref="inventoryCheckFromRef">
        <el-row>
          <el-col :span="12">
            <el-form-item label="仓库/门店：" prop="EntityID">
              <el-select size="small" value-key="ID" v-model="inventoryCheckFrom.EntityName" filterable placeholder="请选择仓库/门店" @change="handleSelectEntity" :disabled="inventoryCheckFrom.isUpdate">
                <el-option value-key="ID" v-for="item in EntityList" :key="item.ID" :label="item.EntityName" :value="item"></el-option>
              </el-select>

            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注信息：">
              <el-input v-model="inventoryCheckFrom.Remark" placeholder="请输入备注信息" size="small"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <div class="tip martp_10">产品明细</div>
        <el-row>
          <el-col :span="24">
            <el-button type="primary" size="small" @click="addProducts">添加产品</el-button>
            <el-button type="danger" size="small" @click="removeMultipleProduct" :disabled="removeDisabled">删除产品</el-button>
          </el-col>
        </el-row>
        <el-table empty-text="暂无产品" size="small" class="martp_10" max-height="400px" :data="inventoryCheckFrom.InventoryCheckDetail" @selection-change="removeHandleChangeSelectProduct">
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column label="产品名称" prop="ProductName">
          </el-table-column>
          <el-table-column label="单位" prop="UnitName">
          </el-table-column>
          <el-table-column label="盘点前数量" prop="BeforeStockQuantity">
            <template slot-scope="scope">
              {{scope.row.BeforeStockQuantity}}
            </template>
          </el-table-column>
          <el-table-column label="盘点后数量" prop="StockQuantity">
            <template slot-scope="scope">
              <el-form-item :show-message="false" :prop="'InventoryCheckDetail.' + scope.$index + '.StockQuantity'" :rules="inventoryCheckFromRules.StockQuantity">
                <el-input v-model="scope.row.StockQuantity" size="small" class="input_type" placeholder="请输入盘点后数量" @input="changeStockQuantity(scope.row)" validate-event v-enter-number v-enter-number2 v-enterInt min="0" type="number"></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="盈亏数量" prop="Quantity">
            <template slot-scope="scope">
              <span v-if="scope.row.Quantity > 0" class="color_green">+{{scope.row.Quantity}}</span>
              <span v-if="scope.row.Quantity < 0" class="color_red">{{scope.row.Quantity}}</span>
              <span v-if="scope.row.Quantity == 0">{{scope.row.Quantity}}</span>
            </template>
          </el-table-column>
          <el-table-column label="备注" prop="Remark">
            <template slot-scope="scope">
              <el-form-item>
                <el-input v-model="scope.row.Remark" size="small" placeholder="请输入备注"></el-input>
              </el-form-item>
            </template>
          </el-table-column>
        </el-table>
      </el-form>

      <div slot="footer">
        <el-button @click="inventoryCheckDialogVisible = false" size="small" v-prevent-click>取 消</el-button>
        <el-button type="primary" plain @click="saveinventoryCheckClick('10')" :loading="inventoryCheckLoading" size="small" v-prevent-click>暂停并保存</el-button>
        <el-button type="primary" @click="saveinventoryCheckClick('20')" :loading="inventoryCheckLoading" size="small" v-prevent-click>完成盘点</el-button>
      </div>
    </el-dialog>

    <!-- 产品选择 -->
    <el-dialog title="添加产品" :visible.sync="selectProductDialogVisible" width="1000px">
      <el-row class="marbm_10">
        <el-col :span="6">
          <el-input v-model="searchProductName" prefix-icon="el-icon-search" size="small" placeholder="请输入产品名称、别名" clearable @change="searchProductList" @clear="searchProductList"></el-input>
        </el-col>
        <el-col :span="4" class="marlt_5">
          <!-- <el-select v-model="PCategoryID" size="small" placeholder="请选择产品分类" :default-first-option="true" clearable filterable @change="searchProductList">
            <el-option :label="item.Name" :value="item.ID" v-for="(item,index) in  classifyList" :key="index">{{item.Name}}</el-option>
          </el-select> -->

              <el-cascader :options="classifyList" :show-all-levels="true" clearable filterable :props="cascaderProps" v-model="PCategoryID" placeholder="请选择话术类目" @change="searchProductList"></el-cascader>
        </el-col>
        <el-col :span="4" class="marlt_5">
          <el-button type="primary" size="small" @click="searchProductList" v-prevent-click>搜 索</el-button>
        </el-col>
      </el-row>
      <el-table size="small" :row-key="getRowKeys" @cell-click="ProductListCellClick" @selection-change="selectionProduct_change" ref="multipleTable" :data="ProductList" :row-class-name="selectProductTableRowClassName">
        <el-table-column type="selection" width="55" :reserve-selection="true" :selectable="checkSelectable"></el-table-column>
        <el-table-column prop="ProductName" label="产品(别名)">
          <template slot-scope="scope">
            <div>{{scope.row.ProductName}}<span v-if="scope.row.Alias" class="font_12 color_999">({{scope.row.Alias}})</span></div>
            <div v-if="scope.row.IsLock">
              <el-tag size="mini" type="warning" effect="dark">{{scope.row.IsLock?'盘点锁定':''}}</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="Specification" label="规格"></el-table-column>
        <el-table-column prop="PCategoryName" label="分类"></el-table-column>
        <el-table-column prop="UnitName" label="单位"></el-table-column>
        <el-table-column prop="Quantity" label="实物库存">
          <template slot-scope="scope">
            {{scope.row.Quantity}}
          </template>
        </el-table-column>
      </el-table>

      <div class="pad_15 text_right">
        <el-pagination background v-if="ProductPaginations.total > 0" @current-change="handleProductCurrentChange" :current-page.sync="ProductPaginations.page" :page-size="ProductPaginations.page_size" :layout="ProductPaginations.layout" :total="ProductPaginations.total"></el-pagination>
      </div>
      <div slot="footer">
        <el-button @click="selectProductDialogVisible = false" size="small" v-prevent-click>取 消</el-button>
        <el-button type="primary" @click="saveInventoryAllocationProductClick" size="small" v-prevent-click>进入盘点</el-button>
      </div>
    </el-dialog>

    <!--库存盘点详情 -->
    <el-dialog custom-class="inventoryCheckDialogClass" title="盘点详情" :visible.sync="inventoryCheckInfoDialogVisible" width="1000px">
      <div class="tip marbm_10" style="margin-top:0">基本信息</div>
      <el-form v-if="inventoryCheckInfo" class="inventoryCheckDialogClassInfoFrom" :inline="true" :inline-message="true" label-width="100px" size="small" :model="inventoryCheckInfo">
        <el-row>
          <el-col :span="8">
            <el-form-item label="单据号：">{{inventoryCheckInfo.ID}}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="盘点仓库："> {{inventoryCheckInfo.EntityName}}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="创建时间：">{{inventoryCheckInfo.CreatedOn}}</el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="8">
            <el-form-item label="申请人：">{{inventoryCheckInfo.CreatedByName}}</el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item label="备注信息：">{{inventoryCheckInfo.Remark}}</el-form-item>
          </el-col>
        </el-row>

        <div class="tip martp_10">产品明细</div>
        <el-tabs v-model="activeName">
          <el-tab-pane name="">
            <span slot="label">全部<span v-if="inventoryCheckInfo.Detail.length > 0">({{inventoryCheckInfo.Detail.length}})</span></span>

            <el-table size="small" max-height="400px" :data="inventoryCheckInfo.Detail">
              <el-table-column label="产品名称" prop="ProductName">
              </el-table-column>
              <el-table-column label="单位" prop="UnitName">
              </el-table-column>
              <el-table-column label="盘点前数量" prop="BeforeStockQuantity">
              </el-table-column>
              <el-table-column label="盘点后数量" prop="StockQuantity">
              </el-table-column>
              <el-table-column label="盈亏数量" prop="Quantity">
                <template slot-scope="scope">
                  <span v-if="scope.row.Quantity > 0" class="color_green">+{{scope.row.Quantity}}</span>
                  <span v-if="scope.row.Quantity < 0" class="color_red">{{scope.row.Quantity}}</span>
                  <span v-if="scope.row.Quantity == 0">{{scope.row.Quantity}}</span>
                </template>
              </el-table-column>
              <el-table-column label="备注" prop="Remark">
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane name="1" v-if="inventoryCheckInfo.shortages.length > 0">
            <span slot="label">盘亏({{inventoryCheckInfo.shortages.length }})</span>
            <el-table size="small" max-height="400px" :data="inventoryCheckInfo.shortages">
              <el-table-column label="产品名称" prop="ProductName">
              </el-table-column>
              <el-table-column label="单位" prop="UnitName">
              </el-table-column>
              <el-table-column label="盘点前数量" prop="BeforeStockQuantity">
              </el-table-column>
              <el-table-column label="盘点后数量" prop="StockQuantity">
              </el-table-column>
              <el-table-column label="盈亏数量" prop="Quantity">
                <template slot-scope="scope">
                  <span v-if="scope.row.Quantity > 0" class="color_green">+{{scope.row.Quantity}}</span>
                  <span v-if="scope.row.Quantity < 0" class="color_red">{{scope.row.Quantity}}</span>
                  <span v-if="scope.row.Quantity == 0">{{scope.row.Quantity}}</span>
                </template>
              </el-table-column>
              <el-table-column label="备注" prop="Remark">
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane name="2" v-if="inventoryCheckInfo.overage.length > 0">
            <span slot="label">盘盈({{inventoryCheckInfo.overage.length}})</span>
            <el-table size="small" max-height="400px" :data="inventoryCheckInfo.overage">
              <el-table-column label="产品名称" prop="ProductName">
              </el-table-column>
              <el-table-column label="单位" prop="UnitName">
              </el-table-column>
              <el-table-column label="盘点前数量" prop="BeforeStockQuantity">
              </el-table-column>
              <el-table-column label="盘点后数量" prop="StockQuantity">
              </el-table-column>
              <el-table-column label="盈亏数量" prop="Quantity">
                <template slot-scope="scope">
                  <span v-if="scope.row.Quantity > 0" class="color_green">+{{scope.row.Quantity}}</span>
                  <span v-if="scope.row.Quantity < 0" class="color_red">{{scope.row.Quantity}}</span>
                  <span v-if="scope.row.Quantity == 0">{{scope.row.Quantity}}</span>
                </template>
              </el-table-column>
              <el-table-column label="备注" prop="Remark">
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>

      </el-form>

    </el-dialog>

  </div>
</template>

<script>
import permission from "@/components/js/permission.js";
import API from "@/api/PSI/Inventory/inventoryCheck";
import APIStorage from "@/api/PSI/Purchase/storage";
// import APIInquire from  "@/api/PSI/Inventory/Inquire";
import APIInventory from "@/api/PSI/Inventory/inventoryDetail";
import APICategory from "@/api/PSI/Product/productCategory";

import dateUtil from "@/components/js/date";
var Enumerable = require("linq");

export default {
  name: "InventoryCheck",
  /**  引入的组件  */
  components: {},
  /**  Vue 实例的数据对象**/
  data() {
    return {
   
      addInventoryDialogVisible: false,
      inventoryCheckDialogVisible: false,
      inventoryCheckLoading: false,
      selectProductDialogVisible: false,
      inventoryCheckInfoDialogVisible: false,

      // isAdd: false, //新增盘点
      // isClose: false, // 作废盘点

      // 列表筛选条件
      searchForm: {
        ID: "",
        DateTime: "",
        BillStatus: "",
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() ? true : false;
        },
      },
      paginations: {
        page: 1, // 当前位于哪页
        total: 0, // 总数
        page_size: 10, // 1页显示多少条
        layout: "total, prev, pager, next, jumper", // 翻页属性
      }, //需要给分页组件传的信息
      BillStatus: [
        { ID: "10", Name: "进行中" },
        { ID: "20", Name: "已完成" },
        { ID: "30", Name: "已作废" },
      ],
      data_list: [], // 列表

      inventoryCheckFrom: {
        BillStatus: "",
        EntityID: "",
        Remark: "",
        InventoryCheckDetail: [],
      },

      inventoryCheckFromRules: {
        EntityID: [
          {
            required: true,
            message: "请选择仓库/门店",
            trigger: ["blur", "change"],
          },
        ],
        StockQuantity: [{ required: true, trigger: ["blur", "change"] }],
      },

      EntityList: [], //仓库列表
      removeDisabled: true,
      ProductList: [],
      searchProductName: "",
      ProductPaginations: {
        page: 1, // 当前位于哪页
        total: 0, // 总数
        page_size: 10, // 1页显示多少条
        layout: "total, prev, pager, next, jumper", // 翻页属性
      }, //需要给分页组件传的信息
      multipleProducts: [],
      selectProductList: [],

      inventoryCheckInfo: "", // 详情
      activeName: "",
      isEdit: false,
      classifyList:[], // 产品分类列表
      PCategoryID:"", // 筛选分类ID
      cascaderProps: {
        value: "ID",
        label: "Name",
        children: "Child",
        emitPath: false,
        expandTrigger: "hover",
      }, // 级联选择器配置项
    };
  },
  // /**计算属性  */
  // computed: {},
  // /** 监听数据变化   */
  // watch: {},
  // /**  路由  */
  // beforeRouteEnter(to, from, next) {
  //   next((vm) => {
  //     /**  新建库存盘点  */
  //     vm.isAdd = permission.permission(
  //       to.meta.Permission,
  //       "PSI-Inventory-InventoryCheck-Add"
  //     );
  //     /**  作废库存盘点 */
  //     vm.isClose = permission.permission(
  //       to.meta.Permission,
  //       "PSI-Inventory-InventoryCheck-Close"
  //     );
  //   });
  // },
  /**  方法集合  */
  methods: {
    /**  搜索  */
    searchDataList() {
      let that = this;
      that.paginations.page = 1;
      that.get_list_inventoryCheck_netWork();
    },
    /**  切换分页  */
    inventoryCheckListHandleCurrentChange(page) {
      let that = this;
      that.paginations.page = page;
      that.get_list_inventoryCheck_netWork();
    },
    /**  状态格式化  */
    BillStatusFormatter(row) {
      switch (row.BillStatus) {
        case "10":
          return "进行中";
          break;

        case "20":
          return "已完成";
          break;

        case "30":
          return "已作废";
          break;
      }
    },
    /** 新增盘点   */
    addInventoryCheck() {
      let that = this;
      that.isEdit = false;
      that.inventoryCheckFrom = {
        BillStatus: "",
        EntityID: "",
        EntityName: "",
        Remark: "",
        InventoryCheckDetail: [],
        // inventoryCheckFromRules: {
        //   EntityID: [{ required: true, message: "请选择盘点仓库", trigger: ["blur", "change"] }],
        //   StockQuantity:[{ required: true, trigger: ["blur", "change"] }],
        // },
      };

      if (that.EntityList.length == 1) {
        let entityItem = that.EntityList[0];
        that.inventoryCheckFrom.EntityID = entityItem.ID;
        that.inventoryCheckFrom.EntityName = entityItem.EntityName;
      }
      that.getProductCategory();
      that.inventoryCheckDialogVisible = true;
    },
    /**  选择盘点仓库  */
    handleSelectEntity(row) {
      let that = this;
      that.inventoryCheckFrom.EntityID = row.ID;

      Enumerable.from(that.ProductList)
        .intersect(
          that.inventoryCheckFrom.InventoryCheckDetail,
          (i) => i.ProductID
        )
        .forEach((item) => {
          that.$refs.multipleTable.toggleRowSelection(item, false);
        });

      (that.ProductPaginations.page = 1),
        (that.searchProductName = ""),
        (that.inventoryCheckFrom.InventoryCheckDetail = []);

      // that.get_stock_list_entityProductListNetwork()
    },

    /**  新增产品  */
    addProducts() {
      let that = this;
      that.$refs["inventoryCheckFromRef"].validateField("EntityID", (valid) => {
        if (!valid) {
          that.searchProductName = "";
          that.searchProductList();
        }
      });
    },

    /**  选择将要删除的产品  */
    removeHandleChangeSelectProduct(selection) {
      this.multipleProducts = selection;
      if (this.multipleProducts.length > 0) {
        this.removeDisabled = false;
      } else {
        this.removeDisabled = true;
      }
    },
    /**  删除所选产品  */
    removeMultipleProduct() {
      var that = this;
      if (that.multipleProducts.length > 0) {
        for (
          var i = 0;
          i < that.inventoryCheckFrom.InventoryCheckDetail.length;
          i++
        ) {
          that.multipleProducts.forEach(function (item) {
            if (that.inventoryCheckFrom.InventoryCheckDetail[i] == item) {
              that.inventoryCheckFrom.InventoryCheckDetail.splice(i, 1);
            }
          });
        }
      }
    },
    /**  设置key  */
    getRowKeys(row) {
      return row.ProductID;
    },
    /** 搜索产品   */
    searchProductList() {
      let that = this;
      that.ProductPaginations.page = 1;
      that.get_stock_list_entityProductListNetwork();
    },
    /**  产品列表分页切换  */
    handleProductCurrentChange(page) {
      let that = this;
      that.ProductPaginations.page = page;
      that.get_stock_list_entityProductListNetwork();
    },
    /**  设置 产品表的key  */
    getRowKeys(row) {
      return row.ProductID;
    },
    /** 当前选中值改变   */
    selectionProduct_change(selection) {
      let that = this;
      that.selectProductList = selection;
    },
    /**  点击产品 行  */
    ProductListCellClick(row, column, cell, event) {
      if (row.IsLock) {
        return;
      }
      this.$refs.multipleTable.toggleRowSelection(row);
    },
    /**  产品状态 是否锁定   */
    checkSelectable(row, index) {
      return !row.IsLock;
    },
    /** 修改 锁定行的背景颜色   */
    selectProductTableRowClassName({ row, rowIndex }) {
      if (row.IsLock) {
        return "IsLockProduct_list_back";
      }
    },
    /**  保存选中的产品  */
    saveInventoryAllocationProductClick() {
      let that = this;
      if (that.selectProductList.length == 0) {
        that.$message.error({
          message: "您没有选择任何产品",
          duration: 2000,
        });
        return;
      }
      that.inventoryCheckFrom.InventoryCheckDetail = Enumerable.from(
        that.selectProductList
      )
        .distinct((i) => i.ID)
        .select((i) => {
          return {
            ProductID: i.ID,
            ProductName: i.ProductName,
            Alias: i.Alias,
            UnitID: i.UnitID,
            UnitName: i.UnitName,
            BeforeStockQuantity: i.Quantity,
            StockQuantity: i.StockQuantity,
            Quantity: isNaN(i.StockQuantity)?"":(parseFloat(i.StockQuantity) - parseFloat(i.Quantity)),
            Remark: i.Remark,
          };
        })
        .toArray();
      that.selectProductDialogVisible = false;
      that.selectProductList = [];
    },
    /**  修改 盘点库存数量  */
    changeStockQuantity(row) {
      row.StockQuantity = Math.floor(row.StockQuantity);
      row.Quantity = row.StockQuantity - row.BeforeStockQuantity;
    },
    /**  关闭添加弹窗  */
    closeAddInventoryCheck() {
      let that = this;
      that.$refs["inventoryCheckFromRef"].clearValidate();
      if (that.$refs.multipleTable) {
        that.$refs.multipleTable.clearSelection();
      }
    },

    /**  保存 盘点  */
    saveinventoryCheckClick(BillStatus) {
      let that = this;
      if (that.inventoryCheckFrom.InventoryCheckDetail.length == 0) {
        that.$message.error({
          message: "请选择盘点产品",
          duration: 2000,
        });
        return;
      }

      let Products = Enumerable.from(
        that.inventoryCheckFrom.InventoryCheckDetail
      )
        .select((i) => {
          return {
            ProductID: i.ProductID,
            UnitID: i.UnitID,
            BeforeStockQuantity: i.BeforeStockQuantity,
            StockQuantity: i.StockQuantity,
            Quantity: i.Quantity,
            Remark: i.Remark,
          };
        })
        .toArray();

      let params = {
        BillStatus: BillStatus,
        EntityID: that.inventoryCheckFrom.EntityID,
        Remark: that.inventoryCheckFrom.Remark,
        InventoryCheckDetail: Products,
      };

      if (that.isEdit) {
        params.ID = that.inventoryCheckFrom.ID;
        if (BillStatus == "10") {
          that.get_update_inventoryCheck_netWork(params);
        } else {
          that.$refs["inventoryCheckFromRef"].validate((valid) => {
            if (valid) {
              that.get_update_inventoryCheck_netWork(params);
            }
          });
        }
      } else {
        if (BillStatus == "10") {
          // that.$refs["inventoryCheckFromRef"].validate(valid=>{
          //   if(valid){
          that.get_create_inventoryCheck_netWork(params);
          // }
          // })
        } else {
          that.$refs["inventoryCheckFromRef"].validate((valid) => {
            if (valid) {
              that.get_create_inventoryCheck_netWork(params);
            }
          });
        }
      }
    },

    /**  显示详情  */
    showOutboundInfo(row, type) {
      let that = this;
      that.get_info_inventoryCheck_netWork(row.ID, 1);
    },
    /**   作废  */
    cancellation(row) {
      let that = this;
      that
        .$confirm("确定要作废该盘点单吗？", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          let params = {
            BillStatus: "30",
            ID: row.ID,
          };
          that.get_update_inventoryCheck_netWork(params);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    /** 编辑 盘点   */
    editInventoryCheck(row) {
      let that = this;
      that.isEdit = true;
      that.getProductCategory();
      that.get_info_inventoryCheck_netWork(row.ID, 2);
    },

    /** ++++++++++++++ ============================================= +++++++++++++ */

    /**  仓库列表  */
    getStorageEntityNetwork: function () {
      var that = this;
      var params = {};
      APIStorage.getpurchaseStorageEntity(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.EntityList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {});
    },
    /** 查询 产品 库存列表 列表  */
    get_stock_list_entityProductListNetwork: function () {
      var that = this;
      var params = {
        PageNum: that.ProductPaginations.page,
        ProductName: that.searchProductName,
        EntityID: that.inventoryCheckFrom.EntityID,
        PCategoryID: that.PCategoryID,
      };
      API.get_inventoryCheckProductList(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.ProductList = res.List;
            that.ProductPaginations.page_size = res.PageSize;
            that.ProductPaginations.total = res.Total;

            that.selectProductDialogVisible = true;

            that.$nextTick(() => {
              that.ProductList.forEach((item) => {
                that.$refs.multipleTable.toggleRowSelection(item, false);
              });

              Enumerable.from(
                that.inventoryCheckFrom.InventoryCheckDetail
              ).forEach((i) => {
                let ProductItem = Enumerable.from(
                  that.ProductList
                ).singleOrDefault((j) => {
                  return j.ID == i.ProductID;
                }, -1);

                if (ProductItem != -1) {
                  ProductItem.IsLock = false;
                  ProductItem.StockQuantity = i.StockQuantity;
                  ProductItem.BeforeStockQuantity = i.Quantity;
                  ProductItem.Remark = i.Remark;
                  that.$refs.multipleTable.toggleRowSelection(ProductItem);
                }
              });
            });
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {});
    },

    // 获取产品分类列表
    getProductCategory: function () {
      var that = this;
      that.loading = true;
      var params = {
        Name: "",
        Active: true,
      };
      APICategory.getProductCategory(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.classifyList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },

    /**  9.1.库存盘点列表   */
    get_list_inventoryCheck_netWork: function () {
      var that = this;
      that.loading = true;
      var params = {
        PageNum: that.paginations.page,
        // ID: that.searchForm.ID,
        EntityID: that.searchForm.EntityID,
        ProductName: that.searchForm.ProductName,
        StartDate:
          that.searchForm.DateTime == null ? "" : that.searchForm.DateTime[0],
        EndDate:
          that.searchForm.DateTime == null ? "" : that.searchForm.DateTime[1],
        BillStatus: that.searchForm.BillStatus,
      };
      API.get_list_inventoryCheck(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.data_list = res.List;
            that.paginations.page_size = res.PageSize;
            that.paginations.total = res.Total;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    /**  9.2.库存盘点详情   */
    get_info_inventoryCheck_netWork: function (ID, type) {
      var that = this;
      that.loading = true;
      var params = {
        ID: ID,
      };
      API.get_info_inventoryCheck(params)
        .then((res) => {
          if (res.StateCode == 200) {
            if (type == 1) {
              that.inventoryCheckInfo = res.Data;
              that.inventoryCheckInfo.shortages = Enumerable.from(
                that.inventoryCheckInfo.Detail
              )
                .where((i) => i.Quantity < 0)
                .toArray();
              that.inventoryCheckInfo.overage = Enumerable.from(
                that.inventoryCheckInfo.Detail
              )
                .where((i) => i.Quantity > 0)
                .toArray();
              that.inventoryCheckInfoDialogVisible = true;
            } else {
              that.inventoryCheckFrom = {
                isUpdate: true,
                ID: res.Data.ID,
                BillStatus: res.Data.BillStatus,
                EntityID: res.Data.EntityID,
                EntityName: res.Data.EntityName,
                Remark: res.Data.Remark,
                InventoryCheckDetail: res.Data.Detail,
              };

              that.inventoryCheckDialogVisible = true;
            }
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    /**  9.3.库存盘点单创建   */
    get_create_inventoryCheck_netWork: function (params) {
      var that = this;
      that.loading = true;
      API.get_create_inventoryCheck(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success({
              message: "操作成功",
              duration: 2000,
            });
            that.inventoryCheckDialogVisible = false;
            that.searchDataList();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    /**  9.4.库存盘点单修改   */
    get_update_inventoryCheck_netWork: function (params) {
      var that = this;
      that.loading = true;
      API.get_update_inventoryCheck(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success({
              message: "操作成功",
              duration: 2000,
            });
            that.inventoryCheckDialogVisible = false;
            that.searchDataList();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
  },
  /**  实例被挂载后调用  */
  mounted() {
    var that = this;
    // var date = new Date(),
    // y = date.getFullYear(),
    // m = date.getMonth();
    // that.searchForm.DateTime = [dateUtil.formatDate.format(new Date(y, m, 1),"YYYY-MM-DD"),dateUtil.formatDate.format(new Date(),"YYYY-MM-DD")];
    that.getStorageEntityNetwork();
    that.searchDataList();
  },
};
</script>

<style lang="scss">
.inventoryCheck {
  .inventoryCheckDialogClass {
    .inventoryCheckDialogClassInfoFrom {
      .el-form-item__label {
        font-size: 13px !important;
      }
      .el-form-item__content {
        font-size: 13px !important;
      }
      .el-form-item {
        margin-bottom: 0px;
      }
      .el-input__inner {
        padding-right: 0;
      }
      .input_type {
        .el-input-group__append {
          padding: 0 10px;
        }
      }
    }
  }
  .IsLockProduct_list_back {
    background-color: #edf2fc;
    cursor: not-allowed;
  }
  .IsLockProduct_list_back:hover > td {
    background-color: #edf2fc !important;
  }
}
.el-cascader {
  line-height: 0;
  .el-input .el-input__inner {
    height: 33px;
  }
  .el-input__suffix {
    top: -3px;
  }
}
</style>
